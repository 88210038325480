import React from "react"
import Layout from "../components/layout"
import revox from '../img/logo-revox.svg'

export default ({ location, data }) => {
  return (
    <Layout location={location}>
      <div className="cover-heading-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-10">
              <h1 className="cover-heading">Building products people love.</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="cover-wrapper cover-products">
        <div className="cover-wrapper-inner">
          <div className="cover-container">
            <div className="inner cover"></div>
          </div>
        </div>
      </div>
      <section className="p-t-100 p-b-20 sm-p-t-30 sm-p-b-0">
        <div className="container p-t-100 p-b-100 sm-p-t-30 sm-p-b-30 ">
          <div className="row">
            <div className="col-sm-8">
              <h1 className="">
                We are product
                <br />
                builders
              </h1>
              <p className="m-t-50">
                We love solving problems (seriously!). Building products is all
                about trying to find solutions to everyday challenges that we
                all face, using the best technology available out there. Check
                out some of our recently launched products that are already
                making a buzz.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div id="pages" className="row m-t-50">
            <div className="col-sm-6">
              <div className="box box-products-pages-1 bg-blue hidden-xs"></div>
            </div>
            <div className="section-content col-sm-6">
              <h1 className="section-title m-t-100 m-b-30 sm-m-t-30">
                Pages dashboard.
              </h1>
              <h4 className="light">
                In collaboration with{" "}
                <a rel="noopener" href="http://revox.io">
                  <img alt="Revox logo" src={revox} width="112" height="22"/>
                </a>
              </h4>

              <div className="box box-products-pages-1 bg-blue visible-xs sm-m-t-30"></div>
              <p className="section-intro m-t-50 sm-m-t-10 sm-m-b-10">
                <a rel="noopener" href="http://revox.io">Revox</a> teamed up with us to create
                a stunning dashboard template, which became one of the{" "}
                <a
                  rel="noopener"
                  href="http://themeforest.net/popular_item/by_category?category=site-templates"
                  target="_blank"
                >
                  best selling themes
                </a>{" "}
                on Envato Themeforest™
              </p>
              <a
                className="btn btn-bordered btn-black btn-rounded btn-xxlg sm-m-t-20 "
                href="http://pages.revox.io/dashboard"
                rel="noopener"
                target="_blank"
              >
                {" "}
                Learn more
              </a>
            </div>
          </div>

          <div className="row m-t-100">
            <div className="section-content col-sm-6 ">
              <h1 className="section-title m-t-100  m-b-30 sm-m-t-30">
                Pages frontend.
              </h1>
              <h4 className="light">
                In collaboration with{" "}
                <a rel="noopener" href="http://revox.io">
                  <img alt="Revox logo" src={revox} width="112" height="22"/>
                </a>
              </h4>
              <div className="box box-products-pages-2 bg-yellow visible-xs sm-m-t-30"></div>
              <p className="section-intro m-t-50 sm-m-t-10 sm-m-b-10">
                A frontend framework based on the same design language used in
                Pages Dashboard. Contains 100s of re-usable blocks and UI
                elements to bootstrap your next project.
              </p>
              <a
                className="btn btn-bordered btn-black btn-rounded btn-xxlg sm-m-t-20"
                href="http://pages.revox.io/frontend"
                rel="noopener"
                target="_blank"
              >
                {" "}
                Learn more
              </a>
            </div>
            <div className="col-sm-6">
              <div className="box box-products-pages-2 bg-yellow hidden-xs"></div>
            </div>
          </div>

          <div id="buddychallenge" className="row m-t-100">
            <div className="col-sm-6">
              <div className="box box-products-buddy-challenge bg-pink hidden-xs"></div>
            </div>
            <div className="section-content col-sm-6">
              <h1 className="section-title m-t-100 sm-m-t-30 sm-m-b-30">
                Buddy Challenge.
              </h1>
              <div className="box box-products-buddy-challenge bg-pink visible-xs"></div>
              <p className="section-intro sm-m-t-10 sm-m-b-10">
                Send fun video challenges to your friends. Capture yourself
                doing something fun and challenge your friends to do the same!
                Available for iOS and Android.
              </p>
              <a
                className="btn btn-bordered btn-black btn-rounded btn-xxlg sm-m-t-20"
                href="https://itunes.apple.com/us/app/buddy-challenge/id1044835322?mt=8"
                target="_blank"
                rel="noopener"
              >
                {" "}
                Learn more
              </a>
            </div>
          </div>

          <div className="row m-t-100 m-b-50 sm-b-30">
            <div className="section-content col-sm-6 ">
              <h1 className="section-title m-t-100  m-b-30 sm-m-t-30">
                Dayvid.
              </h1>
              <div className="box box-products-pages-2 bg-green visible-xs sm-m-t-30"></div>
              <p className="section-intro m-t-50 sm-m-t-10 sm-m-b-10">
                Dayvid is the simplest video journaling/vlogging app you will
                ever find! Create a stunning video montage of your daily moments
                in just 3 taps and share it across social networks! Available
                for iOS.
              </p>
              <a
                className="btn btn-bordered btn-black btn-rounded btn-xxlg sm-m-t-20"
                href="https://itunes.apple.com/gb/app/dayvid-make-everyday-count/id1271637521?mt=8"
                target="_blank"
                rel="noopener"
              >
                {" "}
                Learn more
              </a>
            </div>
            <div className="col-sm-6">
              <div className="box box-products-dayvid hidden-xs"></div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
